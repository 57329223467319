import React from 'react';
import SettingPopup from '../swap/SettingModal';
import SelectPairToken from './SelectPairToken';
import useCollapse from 'react-collapsed';
import NumericInputBox from './NumericInput';
import DepositAmount from './DepositAmoutBlock';
import ConnectWallet from '../connect-wallet';

const PoolCreateModal = () => {
    const config = {
        duration: 2000
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    return (
        <>
        {/* Select Coin Popup Start */}
        <input type="checkbox" id="poolsCreateModal" className="modal-toggle" />
        <label htmlFor='poolsCreateModal' className="modal cursor-pointer backdrop-blur-md">
            <label className="modal-box w-11/12 max-w-5xl rounded-3xl relative bg-white/10">
                <label htmlFor="poolsCreateModal" className="btn btn-sm btn-circle absolute right-5 top-5">✕</label>
                <div className='flex items-start justify-between pb-5 pr-12 border-b border-solid border-white border-opacity-10 rounded-t'>
                    <h3 className="text-lg font-medium">Add Liquidity</h3>
                    <div className='flex items-center space-x-2'>
                        <button className='text-sm text-white'>Clear All</button>
                        <div className="dropdown dropdown-end liquidity-setting-modal">
                            <label tabIndex={0} className="setting w-6 h-6 cursor-pointer text-2xl text-white leading-6 text-opacity-50 hover:text-secondary hover:text-opacity-100 hover:animate-spin m-0 p-0">
                                <i className='las la-cog'></i>
                            </label>
                            <SettingPopup/>
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='grid md:grid-rows-2 md:grid-cols-2 md:grid-flow-col grid-flow-row gap-6'>
                        <div className='col-span-1'>
                            <h3 className='text-lg text-white font-medium mb-4'>Select Pair</h3>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-4'>
                                <div className='col-span-1'>
                                    <SelectPairToken tokenName={"IGT"} tokenIcon={"images/icons/Ignite-icon.png"}/>
                                </div>
                                <div className='col-span-1'>
                                    <SelectPairToken tokenName={"ETH"} tokenIcon={"images/icons/ethereum.svg"}/>
                                </div>
                            </div>
                            <div className='py-4 px-6 border border-gray-200/10 bg-white-50 rounded-3xl mb-4'>
                                <div className='flex items-center justify-between'>
                                    <div className='space-y-3'>
                                        <div className='text-base text-white font-medium'>0.05% fee tier</div>
                                        <span className='px-3 py-1 bg-gray-500/30 text-xs text-white font-medium rounded-full'>87% select</span>
                                    </div>
                                    <button className='p-1 bg-white/80 rounded-md text-sm font-medium text-black' {...getToggleProps()}>{isExpanded ? 'Hide' : 'Edit'}</button>
                                </div>
                            </div>
                            <div {...getCollapseProps()}>
                                <div className='grid grid-cols-4 gap-3'>
                                    <div className='col-span-1'>
                                        <button className='p-2 border border-secondary hover:border-white bg-white-50 rounded-xl text-left relative transition-all duration-700'>
                                            <div className='text-base text-secondary absolute top-2 right-2'>
                                                <i class="las la-check"></i>
                                            </div>
                                            <div className='text-sm text-white font-medium mb-2'>0.01%</div>
                                            <div className='text-xs text-white font-normal'>Best for very stable pairs.</div>
                                        </button>
                                    </div>
                                    <div className='col-span-1'>
                                        <button className='p-2 border border-gray-200/10 hover:border-white bg-white-50 rounded-xl text-left relative transition-all duration-700'>
                                            <div className='text-sm text-white font-medium mb-2'>0.05%</div>
                                            <div className='text-xs text-white font-normal'>Best for stable pairs.</div>
                                        </button>
                                    </div>
                                    <div className='col-span-1'>
                                        <button className='p-2 border border-gray-200/10 hover:border-white bg-white-50 rounded-xl text-left relative transition-all duration-700'>
                                            <div className='text-sm text-white font-medium mb-2'>0.03%</div>
                                            <div className='text-xs text-white font-normal'>Best for most pairs.</div>
                                        </button>
                                    </div>
                                    <div className='col-span-1'>
                                        <button className='p-2 border border-gray-200/10 hover:border-white bg-white-50 rounded-xl text-left relative transition-all duration-700'>
                                            <div className='text-sm text-white font-medium mb-2'>1%</div>
                                            <div className='text-xs text-white font-normal'>Best for exotic pairs.</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row-span-2 col-span-1'>
                            <h3 className='text-lg text-white font-medium mb-4'>Deposit Amounts</h3>
                            <DepositAmount tokenName={"ETH"} tokenIcon={"images/icons/ethereum.svg"}/>
                            <DepositAmount tokenName={"USDT"} tokenIcon={"images/icons/agni-icon.png"}/>
                        </div>

                        <div className='row-span-3 price-range-box'>
                            <h3 className='text-lg text-white font-medium mb-4'>Set Price Range</h3>
                            <div className='flex flex-wrap items-center justify-center text-xs text-white font-medium'>
                                <div className='m-1'>Current Price:</div>
                                <div className='font-semibold m-1'>0.0267014</div>
                                <div className='font-normal m-1'>WETH per MATIC</div>
                            </div>
                            <div className='h-44 flex flex-col space-y-4 items-center justify-center'>
                                <div className='text-6xl text-white text-center'>
                                    <i class="las la-box"></i>
                                </div>
                                <div className='text-xl text-white text-center mt-4'>Your position will appear here.</div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className='col-span-1'>
                                    <NumericInputBox/>
                                </div>
                                <div className='col-span-1'>
                                    <NumericInputBox/>
                                </div>
                                <div className='col-span-2'>
                                    <button className='py-3 px-5 bg-white text-black font-medium text-sm w-full rounded-full'>Full Range</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <div className='col-span-1 md:col-start-2'>
                            <ConnectWallet/>
                        </div>
                    </div>
                </div>
            </label>
        </label>
        </>
    );
};
  
export default PoolCreateModal;