import React from 'react';
import { Outlet, Link } from "react-router-dom";
// import {NavLink} from 'react-router-dom';
import { useState } from "react";
// import { useLocation } from "react-router-dom";
import Menu from "../components/menu/Menu";
import ConnectWallet from "../components/connect-wallet";
import ConnectWalletPopup from "../components/connect-wallet/ConnectWalletModal";

const Layout = () => {
    const [loading] = useState(false);
    
    return (
        <div className=''>
        {loading ? (
            <div className="flex justify-center vertical-align">
                <span className='text-5xl'>Loading</span>
            </div>
        ) : (
            <div className='p-4 md:p-7'>
                <header className='header  relative z-10'>
                    <div className='container mx-auto flex items-center justify-between'>
                        <Link to='/' className='navbar-brand'>
                            <img src='images/logo/agni-logo-text.png' className='h-8 md:h-10 max-w-full' alt='Agni Logo'></img>
                        </Link>
                        <ConnectWallet/>
                    </div>
                </header>
                <Menu/>
                <div className='page-bg w-full h-full fixed top-0 left-0 z-0 opacity-50 blur-md'>
                    <video autoPlay loop muted className='w-full h-full object-cover opacity-75'>
                        <source src="videos/bg-video-2.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className='content-wrapper mt-10 md:mt-20 relative z-10'>
                    <Outlet/>
                </div>
                <ConnectWalletPopup/>
            </div>
        )}
        </div>
    );
};

export default Layout;