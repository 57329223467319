import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

const PoolsCard = () => {
    return (
        <>
            <Link to="single-pools">
                <div className="card rounded-3xl bg-white bg-opacity-5 p-5 backdrop-blur-md relative">

                    <div className='text-base lg:text-lg text-white font-medium tracking-wider text-left mb-4'>WETH / MATIC</div>
                    <div className='text-sm font-medium px-4 py-1 inline-block bg-green-600 bg-opacity-10 text-green-600 rounded-full absolute top-4 right-4'>In range</div>

                    <div className='card-header p-[2px] rounded-xl relative mb-6 before:block before:w-full before:h-full before:bg-gradient-to-r before:from-white before:to-black before:absolute before:top-0 before:left-0 before:opacity-20 before:rounded-xl'>
                        <div className='bg-black bg-opacity-30 rounded-xl p-4 relative flex items-center justify-between z-10'>
                            <div className='text-sm text-white font-medium'>
                                <div className='mb-4'>Min</div>
                                <div>0 WETH per MATIC</div>
                            </div>
                            <div className='text-sm text-white font-medium text-right'>
                                <div className='mb-4'>Max</div>
                                <div>∞  WETH per MATIC</div>
                            </div>
                        </div>
                    </div>
                    <div className="avatar-group -space-x-4 mx-auto z-20 mb-6 md:-mt-12">
                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                            <div className="w-12 h-12 p-2">
                                <img src="images/icons/Ignite-icon.png" className='w-full h-full'></img>
                            </div>
                        </div>
                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                            <div className="w-12 h-12 p-2">
                                <img src="images/icons/ethereum.svg" className='w-full h-full'></img>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <div className='text-sm font-medium px-5 py-2 inline-block bg-white bg-opacity-10 text-white rounded-full mb-1'>0.05%</div>
                    </div>
                    {/* <div className='flex items-center justify-between'>
                        <button className="py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center">Harvest</button>
                        <button className="py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center">Staking</button>
                    </div> */}
                    {/* <div className='border-t border-white border-opacity-10 pt-5 px-5 flex flex-wrap items-center justify-between space-x-2 mt-5'>
                        <div className='text-center'>
                            <div className='text-base font-medium mb-2'>STAKED</div>
                            <div className='text-base font-semibold'>0.0000</div>
                        </div>
                        <div className='text-center'>
                            <div className='text-base font-medium mb-2'>REWARDS</div>
                            <div className='text-base font-semibold'>0.0000</div>
                        </div>
                        <div className='text-center'>
                            <div className='text-base font-medium mb-2'>LOCKED</div>
                            <div className='text-base font-semibold'>0d 0h 0m</div>
                        </div>
                    </div> */}
                </div>
            </Link>
        </>
    );
};
  
export default PoolsCard;