import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Swap from "../components/swap"
import SelectToken from "../components/swap/SelectTokenModal"

const Home = () => {
    console.log("home called")
    return (
        <>
            <section className="swap-section">
                <div className="container mx-auto">
                    <div className="w-full md:w-1/2 lg:w-1/3 mx-auto">
                        <Swap/>             
                    </div>
                </div>
            </section>
            <SelectToken/>
        </>
    );
};
  
export default Home;