import React from 'react';
import PoolsCard from '../components/pools';
import PoolsCard2 from '../components/pools/PoolsCard2';
import PoolsCard3 from '../components/pools/PoolsCard3';
import PoolCreateModal from '../components/pools/PoolCreateModal';
import SelectToken from "../components/swap/SelectTokenModal";

const Pools = () => {
    return(
        <>
            <div className='container mx-auto pb-10'>
                <div className='flex items-center justify-center flex-col sm:flex-row sm:justify-between mb-6'>
                    <h2 className='text-xl md:text-3xl text-white font-semibold tracking-wider'>Pools</h2>
                    <div className='md:flex items-center text-center space-y-3 md:space-y-0 md:space-x-4 mt-4 md:mt-0'>
                        <div className='py-1 px-4 md:px-6 bg-black/80 rounded-full flex flex-col sm:flex-row items-center space-x-3'>
                            <div className='text-sm text-white tracking-wider'>Your positions (1)</div>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text mr-2">Show closed positions</span> 
                                    <input type="checkbox" className="toggle toggle-secondary" />
                                </label>
                            </div>
                        </div>
                        <label htmlFor="poolsCreateModal" className="py-2 px-3 bg-slate-200 hover:bg-slate-700 inline-block text-sm text-center rounded-lg font-medium text-black hover:text-white tracking-wider transition-all duration-700 cursor-pointer leading-4">
                            <div className='flex items-center'>Create Pools <i className="las la-plus text-lg ml-2"></i></div>
                        </label>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-7'>
                    <div className='col-span-1'>
                        <PoolsCard/>
                    </div>
                    <div className='col-span-1'>
                        <PoolsCard/>
                    </div>
                    <div className='col-span-1'>
                        <PoolsCard/>
                    </div>
                </div>
            </div>
            <PoolCreateModal/>
            <SelectToken/>
        </>
    );
};

export default Pools;