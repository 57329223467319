import React from 'react'
import {NavLink} from 'react-router-dom';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Menu = () => {
    const [loading, setLoading] = useState(false);
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        <>
            <nav className='navbar-tab relative z-10 text-center mt-16 w-auto mx-auto'>
                <ul className='w-auto px-4 md:px-8 py-4 md:py-6 bg-white bg-opacity-5 backdrop-blur-md rounded-full flex sm:inline justify-center overflow-x-auto'>
                    <li className={splitLocation[1] === "" ? "active inline-block" : "inline-block"}>
                        <NavLink to="/" className={"px-3 md:px-5 block text-sm md:text-base text-white font-medium whitespace-pre hover:text-secondary transition-all duration-700"}>Swap</NavLink>
                    </li>
                    <li className={splitLocation[1] === "farming" ? "active inline-block" : "inline-block"}>
                        <NavLink to="/farming" className={"px-3 md:px-5 block text-sm md:text-base text-white font-medium whitespace-pre hover:text-secondary transition-all duration-700"}>Farming</NavLink>
                    </li>
                    <li className={splitLocation[1] === "staking-pools" ? "active inline-block" : "inline-block"}>
                        <NavLink to="/staking-pools" className={"px-3 md:px-5 block text-sm md:text-base text-white font-medium whitespace-pre hover:text-secondary transition-all duration-700"}>Staking Pools</NavLink>
                    </li>
                    <li className={splitLocation[1] === "pools" ? "active inline-block" : "inline-block"}>
                        <NavLink to="/pools" className={"px-3 md:px-5 block text-sm md:text-base text-white font-medium whitespace-pre hover:text-secondary transition-all duration-700"}>Pools</NavLink>
                    </li>
                    {/* <li className={splitLocation[1] === "referral" ? "active inline-block" : "inline-block"}>
                        <NavLink to="/blogs" className={"px-5 block text-white font-medium hover:text-secondary transition-all duration-700"}>Referral</NavLink>
                    </li> */}
                </ul>
            </nav>
        </>
    );
};
      
export default Menu;