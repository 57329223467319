import React from 'react';
import {ExchangeFrom, ExchangeTo} from './ExchangeBlock';
import SettingPopup from './SettingModal';

const Swap = () => {
    return (
        <>
        <div className="card border-2 border-white border-opacity-10 rounded-[40px] bg-white bg-opacity-5 backdrop-blur-md">
            <div className="card-head p-5 md:p-8 border-b-2 border-white border-opacity-10 flex items-center justify-between">
                <div className="title-box flex items-center">
                    <div className="icon mr-2">
                        <img src="images/logo/agni-logo-icon.png" className='w-auto h-8'></img>
                    </div>
                    <h3 className="title text-lg text-white font-medium uppercase tracking-widest m-0">Swap</h3>
                </div>
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="setting w-6 h-6 cursor-pointer text-2xl text-white leading-6 text-opacity-50 hover:text-secondary hover:text-opacity-100 hover:animate-spin m-0 p-0">
                        <i className='las la-cog'></i>
                    </label>
                    <SettingPopup/>
                </div>
            </div>
            <div className='card-body p-5 md:p-8'>
                <div>
                    <ExchangeFrom/>
                    <div className='token-exchange relative py-5'>
                        <div className='line w-full h-[2px] bg-black absolute top-1/2 left-0'></div>
                        <button className='w-9 h-9 bg-white rounded-full flex items-center justify-center text-xl text-secondary transition-all duration-700 mx-auto relative'>
                            <i className="las la-long-arrow-alt-down"></i>
                        </button>
                    </div>
                    <ExchangeTo/>
                    <button className='p-4 bg-white hover:bg-secondary text-center block rounded-full font-semibold text-black hover:text-white tracking-wider w-full mt-10 transition-all duration-700'>Exchange</button>
                </div>
            </div>
        </div>
        </>
    );
};
  
export default Swap;