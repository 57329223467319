import React from 'react';
import CountUp from 'react-countup';

const StakePoolCard = (props) => {
    return (
        <>
            <div className="card rounded-3xl bg-white bg-opacity-5 p-5 backdrop-blur-md">
                <div className='card-header p-[2px] rounded-xl relative mb-14 before:block before:w-full before:h-full before:bg-gradient-to-r before:from-white before:to-black before:absolute before:top-0 before:left-0 before:opacity-20 before:rounded-xl'>
                    <div className='bg-black bg-opacity-30 rounded-xl p-4 relative z-10'>
                        <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium'>
                            <div>{props.poolLabel}</div>
                            <div>Earn IGT</div>
                        </div>
                        <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium'>
                            <div>LOCKED APY:</div>
                            <div>-</div>
                        </div>
                    </div>
                    <div className="avatar-group -space-x-4 absolute -bottom-6 left-1/2 -translate-x-1/2 z-20">
                    <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full relative">
                            <div className="w-12 h-12 p-2">
                                <img src={props.poolIcon} className='w-full h-full'></img>
                            </div>
                        </div>
                        {/* <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full">
                            <div className="w-12 h-12 p-2">
                                <img src="images/icons/ethereum.svg" className='w-full h-full'></img>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='text-base text-white font-medium tracking-wider text-center mb-4'>{props.depositLabel}</div>
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Earn</div>
                    <div>IGT + Fees</div>
                </div>
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Multiplier</div>
                    <div>10x</div>
                </div>
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Liquidity</div>
                    <div>$0.00</div>
                </div>
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Total APR</div>
                    <div>0%</div>
                </div>
                <div className="tooltip tooltip-warning inline w-auto" data-tip="Only applies within 3 days of staking. Unstaking after 3 days will not include a fee. Timer resets every time you stake new CAKE in the pool.">
                    <button className='px-5 py-2 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full mb-6'>0.1% unstaking fee if withdrawn within 72h</button>
                </div>
                <div className='mt-6 text-center'>
                    <div className='text-sm font-semibold mb-1'>Stake <span className='text-secondary'>{props.stakeLabel}</span></div>
                    <button class="py-3 px-5 bg-white/80 hover:bg-secondary text-sm text-center block rounded-full font-medium text-black hover:text-white tracking-wider w-auto mx-auto transition-all duration-700 cursor-pointer">Enable</button>
                </div>
                <div className='flex items-center justify-between'>
                    <button className="py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center">Harvest</button>
                    <button className="py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center">Staking</button>
                </div>
                {/* <div className='border-t border-white border-opacity-10 pt-5 px-5 flex flex-wrap items-center justify-between space-x-2 mt-5'>
                    <div className='text-center'>
                        <div className='text-base font-medium mb-2'>STAKED</div>
                        <div className='text-base font-semibold'>0.0000</div>
                    </div>
                    <div className='text-center'>
                        <div className='text-base font-medium mb-2'>REWARDS</div>
                        <div className='text-base font-semibold'>0.0000</div>
                    </div>
                    <div className='text-center'>
                        <div className='text-base font-medium mb-2'>LOCKED</div>
                        <div className='text-base font-semibold'>0d 0h 0m</div>
                    </div>
                </div> */}
            </div>
        </>
    );
};
  
export default StakePoolCard;