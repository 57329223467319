import React from 'react';

const SelectToken = () => {
    return (
        <>
        {/* Select Coin Popup Start */}
        <input type="checkbox" id="my-modal" className="modal-toggle" />
        <label for='my-modal' className="modal cursor-pointer backdrop-blur-md">
            <label className="modal-box rounded-3xl relative bg-white/10">
                <label for="my-modal" className="btn btn-sm btn-circle absolute right-5 top-5">✕</label>
                <div className='flex items-start justify-between pb-5 border-b border-solid border-white border-opacity-10 rounded-t'>
                    <h3 className="text-lg font-medium">Select a Token</h3>
                </div>
                <div className='token-list max-h-96 overflow-y-auto mt-5'>
                    <ul>
                        <li className='border-b last:border-b-0 border-gray-100 border-opacity-5'>
                            <a href='javascript:void(0);' className='tokens-list-item p-3 flex flex-row items-center justify-between rounded-lg hover:bg-white hover:bg-opacity-5 transition-all duration-500'>
                                <div className="token-info flex flex-row items-center justify-start">
                                    <div className="tokan-icon h-8 w-8 mr-2">
                                        <img src="images/icons/Ignite-icon.png" width="32" alt="" className='h-8'></img>
                                    </div>
                                    <div className="tokan-info">
                                        <div className="text-white text-sm font-semibold mb-1">IGT</div>
                                        <div className="text-white text-opacity-50 text-xs">Ignite</div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-end">
                                    <div className="text-white text-opacity-50 text-2sm mr-2">0.0</div>
                                </div>
                            </a>
                        </li>
                        <li className='border-b last:border-b-0 border-gray-100 border-opacity-5'>
                            <a href='javascript:void(0);' className='tokens-list-item p-3 flex flex-row items-center justify-between rounded-lg hover:bg-white hover:bg-opacity-5 transition-all duration-500'>
                                <div className="token-info flex flex-row items-center justify-start">
                                    <div className="tokan-icon h-8 w-8 mr-2">
                                        <img src="images/icons/agni-icon.png" width="32" alt="" className='h-8'></img>
                                    </div>
                                    <div className="tokan-info">
                                        <div className="text-white text-sm font-semibold mb-1">USDT</div>
                                        <div className="text-white text-opacity-50 text-xs">USDT</div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-end">
                                    <div className="text-white text-opacity-50 text-2sm mr-2">0.0</div>
                                </div>
                            </a>
                        </li>
                        <li className='border-b last:border-b-0 border-gray-100 border-opacity-5'>
                            <a href='javascript:void(0);' className='tokens-list-item p-3 flex flex-row items-center justify-between rounded-lg hover:bg-white hover:bg-opacity-5 transition-all duration-500'>
                                <div className="token-info flex flex-row items-center justify-start">
                                    <div className="tokan-icon h-8 w-8 mr-2">
                                        <img src="images/icons/ethereum.svg" width="32" alt="" className='h-8'></img>
                                    </div>
                                    <div className="tokan-info">
                                        <div className="text-white text-sm font-semibold mb-1">ETH</div>
                                        <div className="text-white text-opacity-50 text-xs">Ethereum</div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-end">
                                    <div className="text-white text-opacity-50 text-2sm mr-2">0.0</div>
                                </div>
                            </a>
                        </li>
                        {/* <li className='border-b last:border-b-0 border-gray-100 border-opacity-5'>
                            <a href='javascript:void(0);' className='tokens-list-item p-3 flex flex-row items-center justify-between rounded-lg hover:bg-white hover:bg-opacity-5 transition-all duration-500'>
                                <div className="token-info flex flex-row items-center justify-start">
                                    <div className="tokan-icon h-8 w-8 mr-2">
                                        <img src="images/icons/usdt.svg" width="32" alt="" className='h-8'></img>
                                    </div>
                                    <div className="tokan-info">
                                        <div className="text-white text-sm font-semibold mb-1">USDT</div>
                                        <div className="text-white text-opacity-50 text-xs">USDT</div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-end">
                                    <div className="text-white text-opacity-50 text-2sm mr-2">0.0</div>
                                </div>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <label for="my-modal" className='p-3 bg-white hover:bg-primary text-center block rounded-full font-semibold text-black tracking-wider w-full mt-10 transition-all duration-700 cursor-pointer'>Close</label>
            </label>
        </label>
        {/* Select Coin Popup End */}
        </>
    );
};
  
export default SelectToken;