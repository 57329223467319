import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SettingPopup = () => {
    const metamaskConnect = () => toast("Metamask Connected");
    const trustwalletConnect = () => toast.error("Provider Error - No provider was found",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return (
        <>
        {/* Select Coin Popup Start */}
        <div tabIndex={0} className="dropdown-content menu card border-2 border-white border-opacity-10 rounded-lg bg-black w-80 p-5">
            <h3 className='text-base text-white font-medium tracking-wider mb-4'>Transaction Settings</h3>
            <div className='text-sm text-white/80 font-medium tracking-wider mb-2'>
                <span>Slippage tolerance</span>
                <div className="tooltip tooltip-secondary tooltip-left inline w-auto text-xs" data-tip="Your transaction will revert if the price changes unfavorably by more than this percentage.">
                    <button className='px-2 py-1 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full ml-1'>?</button>
                </div>
            </div>
            <div className='flex items-center space-x-4 mb-2'>
                <button className='py-2 px-5 bg-secondary text-white rounded-full text-sm'>Auto</button>
                <div className='input-box py-1 px-2 border border-white/30 w-full bg-white/20 rounded-full flex items-center space-x-2'>
                    <input placeholder="0.10" className='w-full border-0 bg-transparent focus:ring-0 focus:outline-none text-sm text-right text-white placeholder:text-white'></input>
                    <span>%</span>
                </div>
            </div>
            <div className='text-sm text-white/80 font-medium tracking-wider mb-2'>
                <span>Transaction deadline</span>
                <div className="tooltip tooltip-secondary tooltip-left inline w-auto text-xs" data-tip="Your transaction will revert if it is pending for more than this period of time.">
                    <button className='px-2 py-1 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full ml-1'>?</button>
                </div>
            </div>
            <div className='flex items-center space-x-2 mb-2'>
                <div className='input-box py-1 px-2 border border-white/30 w-auto bg-white/20 rounded-full'>
                    <input placeholder="0.10" className='w-14 border-0 bg-transparent focus:ring-0 focus:outline-none text-sm text-right text-white placeholder:text-white'></input>
                </div>
                <div className='text-sm text-white/80 font-medium tracking-wider'>minutes</div>
            </div>
            <h3 className='text-base text-white font-medium tracking-wider mb-2 mt-3'>Interface Settings</h3>
            <div className='flex items-center justify-between space-x-2 mb-2'>
                <div>
                    <span>Auto Router API</span>
                    <div className="tooltip tooltip-secondary tooltip-left inline w-auto text-xs" data-tip="Use the Uniswap Labs API to get faster quotes.">
                        <button className='px-2 py-1 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full ml-1'>?</button>
                    </div>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        {/* <span className="label-text">Remember me</span>  */}
                        <input type="checkbox" className="toggle toggle-secondary" chacked />
                    </label>
                </div>
            </div>
            <div className='flex items-center justify-between space-x-2 mb-2'>
                <div>
                    <span>Expert Mode</span>
                    <div className="tooltip tooltip-secondary tooltip-left inline w-auto text-xs" data-tip="Allow high price impact trades and skip the confirm screen. Use at your own risk.">
                        <button className='px-2 py-1 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full ml-1'>?</button>
                    </div>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        {/* <span className="label-text">Remember me</span>  */}
                        <input type="checkbox" className="toggle toggle-secondary" />
                    </label>
                </div>
            </div>
        </div>
        {/* Select Coin Popup End */}
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            />
            <ToastContainer />
        </>
    );
};
  
export default SettingPopup;