import React from 'react'

function ExchangeFrom () {
    return (
        <>
            <div className='exchange-block'>
                <div className='flex items-center justify-between text-base font-medium text-white mb-2'>
                    <label className=''>From</label>
                    <div className='coin-balance flex items-center'>
                        <i className='las la-wallet text-2xl mr-2'></i>
                        <span>0.0</span>
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <label htmlFor="my-modal" className='p-4 bg-black hover:bg-white text-white hover:text-black rounded-full flex items-center cursor-pointer transition-all duration-700'>
                        <div className='coin-icon mr-2'><img src='images/icons/Ignite-icon.png' className='w-5 h-5 rounded-full'></img></div>
                        <div className='coin-name text-sm font-medium mr-4'>IGT</div>
                        <i className="las la-angle-down text-xs"></i>
                    </label>
                    <div className='coin-amount text-base font-medium text-white'>
                        <input className='max-w-full w-full bg-transparent border-0 p-0 text-base placeholder:text-white outline-none focus:outline-none focus:ring-0 tracking-widest heading-font text-right' inputmode="decimal" autocomplete="off" autocorrect="off" pattern="^[0-9]*[.,]?[0-9]*$" maxlength="79" spellcheck="false" placeholder="$ 0.00"></input>
                    </div>
                </div>
            </div>
        </>
    );
};

function ExchangeTo () {
    return (
        <>
            <div className='exchange-block'>
                <div className='flex items-center justify-between text-base font-medium text-white mb-2'>
                    <label className=''>To</label>
                    <div className='coin-balance flex items-center'>
                        <i className='las la-wallet text-2xl mr-2'></i>
                        <span>0.0</span>
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <label htmlFor="my-modal" className='p-4 bg-black hover:bg-white text-white hover:text-black rounded-full flex items-center cursor-pointer transition-all duration-700'>
                        <div className='coin-icon mr-2'><img src='images/icons/agni-icon.png' className='w-5 h-5 rounded-full'></img></div>
                        <div className='coin-name text-sm font-medium mr-4'>USDT</div>
                        <i className="las la-angle-down text-xs"></i>
                    </label>
                    <div className='coin-amount text-base font-medium text-white'>
                        <input className='max-w-full w-full bg-transparent border-0 p-0 text-base placeholder:text-white outline-none focus:outline-none focus:ring-0 tracking-widest heading-font text-right' inputmode="decimal" autocomplete="off" autocorrect="off" pattern="^[0-9]*[.,]?[0-9]*$" maxlength="79" spellcheck="false" placeholder="$ 0.00"></input>
                    </div>
                </div>
            </div>
        </>
    );
};
  
export {ExchangeFrom, ExchangeTo};