import React from 'react';
import StakePoolCard from '../components/pools/StakePoolCard';
import PoolCreateModal from '../components/pools/PoolCreateModal';
import SelectToken from "../components/swap/SelectTokenModal";

const StakingPools = () => {
    return(
        <>
            <div className='container mx-auto pb-10'>
                <div className='flex items-center justify-center flex-col sm:flex-row sm:justify-between mb-6'>
                    <h2 className='text-xl md:text-3xl text-white font-semibold tracking-wider'>Staking Pools</h2>
                    <label htmlFor="poolsCreateModal" className="py-2 px-3 bg-slate-200 hover:bg-slate-700 text-sm text-center rounded-lg font-medium text-black hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-4 flex items-center">Create Pools <i className="las la-plus text-lg ml-2"></i></label>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-7'>
                    <div className='col-span-1'>
                        <StakePoolCard poolLabel={"IGT Pool"} depositLabel={"Deposit IGT and earn IGT"} poolIcon={"images/icons/Ignite-icon.png"} stakeLabel={"IGT"}/> 
                    </div>
                    <div className='col-span-1'>
                        <StakePoolCard poolLabel={"ETH Pool"} depositLabel={"Deposit ETH and earn IGT"} poolIcon={"images/icons/ethereum.svg"} stakeLabel={"ETH"}/>
                    </div>
                    <div className='col-span-1'>
                        <StakePoolCard poolLabel={"Tether Pool"} depositLabel={"Deposit USDT and earn IGT"} poolIcon={"images/icons/agni-icon.png"} stakeLabel={"USDT"}/>
                    </div>
                </div>
            </div>
            <PoolCreateModal/>
            <SelectToken/>
        </>
    );
};

export default StakingPools;