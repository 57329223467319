import React from "react";

function DepositAmount(props){
    return(
        <>
            <div className="py-3 px-5 pr-3 bg-white/20 hover:bg-black text-black hover:text-white rounded-2xl relative transition-all duration-700 mb-4">
                <div className="flex items-center justify-between w-full">
                    <div className='coin-amount text-base font-medium text-white'>
                        <input className='max-w-full w-full bg-transparent border-0 p-0 text-base placeholder:text-white outline-none focus:outline-none focus:ring-0 tracking-widest heading-font text-left' inputmode="decimal" autocomplete="off" autocorrect="off" pattern="^[0-9]*[.,]?[0-9]*$" maxlength="79" spellcheck="false" placeholder="$ 0.00"></input>
                    </div>
                    <button className="p-2 bg-white/20 hover:bg-primary text-white hover:text-black rounded-full flex items-center transition-all duration-700 ">
                        <img src={props.tokenIcon} className="w-6 h-6 rounded-full"></img>
                        <div className="coin-name text-sm font-medium ml-2">{props.tokenName}</div>
                    </button>
                </div>
                <div className="text-sm text-white font-medium">lorem ipsum dolor sit amet</div>
            </div>
        </>
    )
}

export default DepositAmount;