import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConnectWalletPopup = () => {
    const metamaskConnect = () => toast("Metamask Connected");
    const trustwalletConnect = () => toast.error("Provider Error - No provider was found",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return (
        <>
        {/* Select Coin Popup Start */}
        <input type="checkbox" id="connectWallet-modal" className="modal-toggle" />
        <label htmlFor='connectWallet-modal' className="modal cursor-pointer backdrop-blur-md">
            <label className="modal-box w-11/12 max-w-5xl rounded-3xl relative bg-white/10">
                <label htmlFor="connectWallet-modal" className="btn btn-sm btn-circle absolute right-5 top-5">✕</label>
                <div className='flex items-start justify-between pb-5 border-b border-solid border-white border-opacity-10 rounded-t'>
                    <h3 className="text-lg font-medium">Select a Token</h3>
                </div>
                <div className='overflow-y-auto mt-6'>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                        <div className='col-span-1'>
                            <div className='card bg-white bg-opacity-5 rounded-3xl p-4 md:p-6 h-full'>
                                <div className='flex items-center justify-between w-full h-full space-x-2'>
                                    <div className='text-left w-4/5'>
                                        <h3 className='text-lg text-white mb-2 tracking-wider font-semibold'>Metamask</h3>
                                        <p className='text-sm text-gray-400 tracking-wider font-medium leading-6'>Connect using metamask extension (on desktop) or application (on Android/iOS devices).</p>
                                    </div>
                                    <div className='icon w-1/5'>
                                        <img src='images/icons/metamask.svg' className='w-full h-auto'></img>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 items-center justify-between mt-5'>
                                    <a href="javascript:void(0);" onClick={metamaskConnect} className='py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wider transition-all duration-700 cursor-pointer leading-3 flex items-center'>Connect</a>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Get Metamask</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <div className='card bg-white bg-opacity-5 rounded-3xl p-4 md:p-6 h-full'>
                                <div className='flex items-center justify-between w-full h-full space-x-2'>
                                    <div className='text-left w-4/5'>
                                        <h3 className='text-lg text-white mb-2 tracking-wider font-semibold'>Trustwallet</h3>
                                        <p className='text-sm text-gray-400 tracking-wider font-medium leading-6'>Connect using trustwallet application (on Android devices).</p>
                                    </div>
                                    <div className='icon w-1/5'>
                                        <img src='images/icons/trustwallet.png' className='w-full h-auto'></img>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 items-center justify-between mt-5'>
                                    <a href="javascript:void(0);" onClick={trustwalletConnect} className='py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wider mt-all duration-700 cursor-pointer leading-3 flex items-center'>Connect</a>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Get Trustwallet</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <div className='card bg-white bg-opacity-5 rounded-3xl p-4 md:p-6 h-full'>
                                <div className='flex items-center justify-between w-full h-full space-x-2'>
                                    <div className='text-left w-4/5'>
                                        <h3 className='text-lg text-white mb-2 tracking-wider font-semibold'>Wallet Connect</h3>
                                        <p className='text-sm text-gray-400 tracking-wider font-medium leading-6'>Connect using your wallet with the top providers from wallet connect list (on Android/iOS devices)</p>
                                    </div>
                                    <div className='icon w-1/5'>
                                        <img src='images/icons/wallet_connect.png' className='w-full h-auto'></img>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 items-center justify-between mt-5'>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wi-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Connect</a>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Check Wallet Connect</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <div className='card bg-white bg-opacity-5 rounded-3xl p-4 md:p-6 h-full'>
                                <div className='flex items-center justify-between w-full h-full space-x-2'>
                                    <div className='text-left w-4/5'>
                                        <h3 className='text-lg text-white mb-2 tracking-wider font-semibold'>Ignite Wallet</h3>
                                        <p className='text-sm text-gray-400 tracking-wider font-medium leading-6'>Connect using Ignite chain extension (on desktop)</p>
                                    </div>
                                    <div className='icon w-1/5'>
                                        <img src='images/icons/Ignite-icon.png' className='w-full h-auto'></img>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 items-center justify-between mt-5'>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-wider mt-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Connect</a>
                                    <a href="javascript:void(0);" className='py-4 px-4 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wi-4 transition-all duration-700 cursor-pointer leading-3 flex items-center'>Get Ignite chain Wallet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
        </label>
        {/* Select Coin Popup End */}
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            />
            <ToastContainer />
        </>
    );
};
  
export default ConnectWalletPopup;