import { transparent } from "daisyui/src/colors";
import React from "react";
// es6
// import NumericInput from 'react-numeric-input';
// or es5
// var NumericInput = require('react-numeric-input');
// or TypeScript
import * as NumericInput from "react-numeric-input";

function NumericInputBox(){
    return(
        <>
            <div className='p-4 border border-white/50 hover:border-secondary bg-white-50 rounded-xl text-center relative'>
                <div className='text-sm font-medium text-white'>Min Price</div>
                <NumericInput 
                    className="form-control mt-4" 
                    value={ 10.25 } 
                    min={ 0 } 
                    max={ 1000 } 
                    step={ 0.01 } 
                    precision={ 0 } 
                    pattern="[0-9].[0-9][0-9]"
                    size={ 5 } 
                    mobile
                    style={{
                        input:{
                            background: transparent,
                            width: '100%',
                            fontSize: 14,
                            outline: 0,
                            fontWeight: 500,
                        },
                        arrowUp: {
                            borderBottomColor: 'rgba(255, 255, 255, 0.63)',
                            backgroundColor: 'rgba(255,255,255,1)',
                            color: 'rgba(255,255,255,1)',
                        },
                        arrowDown: {
                            borderTopColor: 'rgba(255, 255, 255, 0.63)',
                            backgroundColor: 'rgba(255,255,255,1)',
                            color: 'rgba(255,255,255,1)',
                        }
                    }}
                />
                <div className='text-sm font-medium text-white mt-4'>per ETH</div>
            </div>
        </>
    )
}
export default NumericInputBox;