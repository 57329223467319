import React from "react";

function SelectPairToken(props){
    return(
        <>
            <label htmlFor="my-modal" className='p-4 pr-8 bg-white hover:bg-black text-black hover:text-white rounded-full relative flex items-center cursor-pointer transition-all duration-700'>
                <div className='coin-icon mr-2'><img src={props.tokenIcon} className='w-6 h-6 rounded-full'></img></div>
                <div className='coin-name text-sm font-medium mr-4'>{props.tokenName}</div>
                <i className="las la-angle-down text-lg absolute top-1/2 right-5 transform -translate-y-1/2"></i>
            </label>
        </>
    )
}

export default SelectPairToken;