import React from 'react'
import { Outlet, Link } from "react-router-dom";
import FarmingCard from "../components/farming"
import FarmingCard2 from "../components/farming/FarmingCard2"
import FarmingCard3 from "../components/farming/FarmingCard3"

const Farming = () => {
    return (
        <>
            <div className='container mx-auto'>
                <h2 className='text-xl md:text-3xl text-white font-semibold tracking-wider text-center mb-6'>Farming</h2>
                <div className='flex flex-wrap space-y-5 md:space-y-0 md:space-x-7 place-content-center place-self-center'>
                    <div className='w-full md:w-1/2 lg:w-1/3'>
                        <FarmingCard/>
                    </div>
                    <div className='w-full md:w-1/2 lg:w-1/3'>
                        <FarmingCard2/>
                    </div>
                    {/* <div className='col-span-1'>
                        <FarmingCard3/>
                    </div> */}
                </div>
            </div>
        </>
    );
};
  
export default Farming;