import React from 'react';
import SelectToken from "../components/swap/SelectTokenModal";
import { Link } from 'react-router-dom';

const SinglePools = () => {
    return(
        <>
            <div className='container mx-auto pb-10'>
                <div className='sm:w-11/12 max-w-5xl p-0 md:p-0 rounded-3xl relative bg-white/0 mx-auto'>
                    <div className='flex items-start justify-between flex-col lg:flex-row space-y-3 lg:space-y-0 pb-5 border-b border-solid border-white border-opacity-10'>
                        <div className='flex lg:items-center flex-col lg:flex-row space-y-3 lg:space-y-0 md:space-x-3'>
                            <div className='flex items-center space-x-3'>
                                <Link to="/pools" className='text-3xl text-white/60 hover:text-secondary'>
                                    <i className="las la-long-arrow-alt-left"></i>
                                </Link>
                                <div className="avatar-group -space-x-2">
                                    <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                        <div className="w-8 h-8 p-1">
                                            <img src="../images/icons/Ignite-icon.png" className='w-full h-full'></img>
                                        </div>
                                    </div>
                                    <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                        <div className="w-8 h-8 p-1">
                                            <img src="../images/icons/ethereum.svg" className='w-full h-full'></img>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-lg font-medium uppercase">WETH / MATIC</h3>
                            </div>
                            <div className='flex items-center space-x-3'>
                                <div className='text-sm font-medium px-4 py-1 inline-block bg-white bg-opacity-10 text-white rounded-full'>0.05%</div>
                                <div className='text-sm font-medium px-4 py-1 inline-block bg-green-600 bg-opacity-10 text-green-600 rounded-full'>In range</div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center space-x-3'>
                            <button className="py-3 px-3 bg-white hover:bg-stone-700 text-sm text-center rounded-lg font-medium text-stone-700 hover:text-white tracking-wider transition-all duration-700 cursor-pointer leading-3 flex items-center">Increase Liquidity</button>
                            <button className="py-3 px-3 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-widertransition-all duration-700 cursor-pointer leading-3 flex items-center">Remove Liquidity</button>
                        </div>
                    </div>
                    <div className='card-body p-0 pt-4 md:pt-6'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                            <div className='col-span-1'>
                                <div className='card-header h-full p-4 rounded-xl relative mb-6 before:block before:w-full before:h-full before:bg-gradient-to-br before:from-secondary before:to-black before:absolute before:top-0 before:left-0 before:opacity-20 before:rounded-xl'>
                                    <div className='relative z-10'>
                                        {/* <div className='text-xs text-white/50 animate-pulse mb-3'>0x9c3c9283d3e44854697cd22d3faa240cfb032889 • MATIC </div>
                                        <div className="text-base lg:text-lg text-white font-medium tracking-wider text-left">WETH / MATIC</div>
                                        <div className="text-base lg:text-lg text-white font-normal tracking-wider text-left mb-6">0.05%</div>
                                        <div className='flex flex-col items-start space-y-2'>
                                            <div className='text-xs font-medium px-4 py-1 w-auto bg-white bg-opacity-10 text-white tracking-wider rounded-lg'><span className='text-white/60 uppercase'>id: </span> 5765</div>
                                            <div className='text-xs font-medium px-4 py-1 w-auto bg-white bg-opacity-10 text-white tracking-wider rounded-lg'><span className='text-white/60 uppercase'>Min Tick: </span> -887270</div>
                                            <div className='text-xs font-medium px-4 py-1 w-auto bg-white bg-opacity-10 text-white tracking-wider rounded-lg'><span className='text-white/60 uppercase'>Max Tick: </span> 887270</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-1'>
                                <div className='space-y-4'>
                                    <div className='card-header h-full p-4 rounded-xl relative bg-gradient-to-br from-white/20 to-black/20'>
                                        <h3 class="text-base text-white font-medium mb-4 pb-2 border-b border-white/10">Liquidity</h3>
                                        <div className='text-xl md:text-2xl text-white mb-4'>$- 20.55</div>
                                        <div className='p-4 rounded-xl relative bg-black/20'>
                                            <div className='relative space-y-4 z-10'>
                                                <div className='flex sm:items-center justify-between flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                                            <div className="w-6 h-6 p-1">
                                                                <img src="../images/icons/Ignite-icon.png" className='w-full h-full'></img>
                                                            </div>
                                                        </div>
                                                        <div class="text-sm text-white/60 uppercase">WETH</div>
                                                    </div>
                                                    <div className='flex items-center justify-between space-x-3'>
                                                        <div class="text-sm text-white/60 uppercase">0.00004656</div>
                                                        <div className='text-xs font-medium px-2 py-1 inline-block bg-white bg-opacity-10 text-white rounded-full'>50%</div>
                                                    </div>
                                                </div>
                                                <div className='flex sm:items-center justify-between flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                                            <div className="w-6 h-6 p-1">
                                                                <img src="../images/icons/ethereum.svg" className='w-full h-full'></img>
                                                            </div>
                                                        </div>
                                                        <div class="text-sm text-white/60 uppercase">MATIC</div>
                                                    </div>
                                                    <div className='flex items-center justify-between space-x-3'>
                                                        <div class="text-sm text-white/60 uppercase">0.001092</div>
                                                        <div className='text-xs font-medium px-2 py-1 inline-block bg-white bg-opacity-10 text-white rounded-full'>50%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-header h-full p-4 rounded-xl relative bg-gradient-to-br from-white/20 to-black/20'>
                                        <div className='flex items-center justify-between mb-4 pb-2 border-b border-white/10'>
                                            <h3 class="text-base text-white font-medium">Unclaimed fees</h3>
                                            <button className="py-3 px-3 bg-white hover:bg-secondary text-sm text-center rounded-lg font-medium text-secondary hover:text-white tracking-widertransition-all duration-700 cursor-pointer leading-3 flex items-center">Collect fees</button>
                                        </div>
                                        <div className='text-xl md:text-2xl text-white mb-4'>$- 20.55</div>
                                        <div className='p-4 rounded-xl relative bg-black/20'>
                                            <div className='relative space-y-4 z-10'>
                                                <div className='flex sm:items-center justify-between flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                                            <div className="w-6 h-6 p-1">
                                                                <img src="../images/icons/Ignite-icon.png" className='w-full h-full'></img>
                                                            </div>
                                                        </div>
                                                        <div class="text-sm text-white/60 uppercase">WETH</div>
                                                    </div>
                                                    <div className='flex items-center justify-between space-x-3'>
                                                        <div class="text-sm text-white/60 uppercase">0.00004656</div>
                                                        <div className='text-xs font-medium px-2 py-1 inline-block bg-white bg-opacity-10 text-white rounded-full'>50%</div>
                                                    </div>
                                                </div>
                                                <div className='flex sm:items-center justify-between flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full shadow-lg relative">
                                                            <div className="w-6 h-6 p-1">
                                                                <img src="../images/icons/ethereum.svg" className='w-full h-full'></img>
                                                            </div>
                                                        </div>
                                                        <div class="text-sm text-white/60 uppercase">MATIC</div>
                                                    </div>
                                                    <div className='flex items-center justify-between space-x-3'>
                                                        <div class="text-sm text-white/60 uppercase">0.001092</div>
                                                        <div className='text-xs font-medium px-2 py-1 inline-block bg-white bg-opacity-10 text-white rounded-full'>50%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between space-x-2 mt-2'>
                                            <div>
                                                <span>Collect as WMATIC</span>
                                            </div>
                                            <div className="form-control">
                                                <label className="label cursor-pointer">
                                                    {/* <span className="label-text">Remember me</span>  */}
                                                    <input type="checkbox" className="toggle toggle-secondary" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4 rounded-xl relative before:block before:w-full before:h-full before:bg-gradient-to-bl before:from-white before:to-black before:absolute before:top-0 before:left-0 before:opacity-20 before:rounded-xl'>
                            <div className='relative z-10'>
                                <div className='flex items-start justify-between pb-5 border-b border-solid border-white border-opacity-10 mb-5'>
                                    <div className='flex items-center space-x-3'>
                                        <h3 className="text-lg font-medium">Price range</h3>
                                        <div className='text-sm font-medium px-4 py-1 inline-block bg-green-600 bg-opacity-10 text-green-600 rounded-full'>In range</div>
                                    </div>
                                </div>
                                <div className='flex items-center justify-between flex-col lg:flex-row space-y-3 lg:space-y-0 space-x-0 lg:space-x-5 mb-3 lg:mb-5'>
                                    <div className='w-full p-4 rounded-xl relative bg-black/20 text-center space-y-2'>
                                        <div className='text-sm text-white/80'>Min price</div>
                                        <div className='text-lg text-white font-semibold'>10.00</div>
                                        <div className='text-sm text-white/80'>WETH per MATIC</div>
                                        <div className='text-xs text-white/60'>Your position will be 100% MATIC at this price.</div>
                                    </div>
                                    <div className='text-2xl text-white/50'><i className="las la-arrows-alt-h rotate-90 lg:rotate-0"></i></div>
                                    <div className='w-full p-4 rounded-xl relative bg-black/20 text-center space-y-2'>
                                        <div className='text-sm text-white/80'>Min price</div>
                                        <div className='text-lg text-white font-semibold'>∞ 10.00</div>
                                        <div className='text-sm text-white/80'>WETH per MATIC</div>
                                        <div className='text-xs text-white/60'>Your position will be 100% MATIC at this price.</div>
                                    </div>
                                </div>
                                <div className='w-full p-4 rounded-xl relative bg-black/20 text-center space-y-2'>
                                    <div className='text-sm text-white/80'>Current price</div>
                                        <div className='text-lg text-white font-semibold'>∞ 10.00</div>
                                        <div className='text-sm text-white/80'>WETH per MATIC</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SelectToken/>
        </>
    );
};

export default SinglePools;