import React from 'react'
import { Outlet, Link } from "react-router-dom";

const FarmingCard2 = () => {
    return (
        <>
            <div className="card rounded-3xl bg-white bg-opacity-5 p-5 backdrop-blur-md">
                <div className='card-header p-[2px] rounded-xl relative mb-14 before:block before:w-full before:h-full before:bg-gradient-to-r before:from-white before:to-black before:absolute before:top-0 before:left-0 before:opacity-20 before:rounded-xl'>
                    <div className='bg-black bg-opacity-30 rounded-xl p-4 relative z-10'>
                        <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium'>
                            <div>Total staked</div>
                            <div>$0.00</div>
                        </div>
                        <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium'>
                            <div>$0.00</div>
                            <div>-</div>
                        </div>
                    </div>
                    <div className="avatar-group -space-x-4 absolute -bottom-6 left-1/2 -translate-x-1/2 z-20">
                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full relative">
                            <div className="w-12 h-12 p-2">
                                <img src="images/icons/Ignite-icon.png" className='w-full h-full'></img>
                            </div>
                        </div>
                        <div className="border-2 bg-white border-white border-opacity-20 overflow-hidden rounded-full">
                            <div className="w-12 h-12 p-2">
                                <img src="images/icons/ethereum.svg" className='w-full h-full'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-base text-white font-medium tracking-wider text-center mb-4'>Deposit IGT-ETH and earn IGT</div>
                <div className='px-5 py-2 text-xs bg-white bg-opacity-10 text-white mx-auto rounded-full mb-6'>Ended</div>
                {/* <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Ended at</div>
                    <div>24 Mar 2022 9:00 UTC</div>
                </div> */}
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>Your stake</div>
                    <div>$0.00</div>
                </div>
                <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>IGT reward</div>
                    <div>$0.00</div>
                </div>
                {/* <div className='flex items-center justify-between mb-4 last:mb-0 text-sm text-white font-medium tracking-wider'>
                    <div className='opacity-60'>LDO reward</div>
                    <div>$0.00</div>
                </div> */}
                <button class="p-3 bg-white/80 hover:bg-secondary text-sm text-center block rounded-full font-medium text-black hover:text-white tracking-wider w-full mt-4 transition-all duration-700 cursor-pointer">Connect Wallet</button>
            </div>
        </>
    );
};
  
export default FarmingCard2;