import React from 'react'

const ConnectWallet = () => {
    return (
        <>
            <label htmlFor="connectWallet-modal" className='connectWallet py-3 px-4 flex items-center justify-center text-sm md:text-base font-medium bg-secondary text-white hover:bg-white hover:text-secondary rounded-lg drop-shadow-[0_10px_26px_rgba(227,227,79,0.30)] transition-all duration-700 cursor-pointer'>
                <span>Connect Wallet</span> 
                {/* <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> */}
            </label>
        </>
    );
};
  
export default ConnectWallet;